import React from "react";
import styled from "styled-components";
import * as colors from "../utilities/colors";

// Styled Components
const FooterContainer = styled.footer`
  align-items: center;
  display: flex;
  filter: desaturate(100%);
  flex-direction: column;
  justify-content: center;
  mix-blend-mode: plus-lighter;
  padding: 15em 8vw 0;
  margin-top: 2em;
  text-align: center;
  width: 100%;
  z-index: 2;
`;

const FooterNav = styled.nav`
  margin-bottom: 1em;
  display: flex;
  align-items: center;

  a {
    color: ${colors.lightPoint};
    font-size: 0.875em;
    opacity: 0.66;
    padding: 1em;

    &:hover {
      opacity: 1;
    }
  }
`;

const Copyright = styled.div`
  font-size: 0.8em;
  line-height: 1.4;

  &,
  a {
    color: #707070;
  }

  a {
    &:hover {
      color: ${colors.lightPoint};
    }
  }
`;

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <FooterContainer>
      <FooterNav>
        <a
          href="https://www.anonymoushealth.com/privacy-policy"
          rel="noreferrer"
          target="_blank"
        >
          Privacy Policy
        </a>
        <a
          href="https://www.anonymoushealth.com/terms"
          rel="noreferrer"
          target="_blank"
        >
          Terms &amp; Conditions
        </a>
      </FooterNav>
      <Copyright>
        &copy; {currentYear}&nbsp;
        <a
          href="https://www.anonymoushealth.com/"
          rel="noreferrer"
          target="_blank"
        >
          Anonymous Health, Inc.
        </a>
        &nbsp;All Rights Reserved.
      </Copyright>
    </FooterContainer>
  );
};

export default Footer;
