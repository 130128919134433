// Libraries
import { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
// import PseudoButton from '../PseudoButton';
// Utilities
import * as colors from "../../utilities/colors";
import Button from "../Button";
// Components
import H1 from "../H1";

const stepContent = {
  1: {
    heading: "We all have habits we'd like to change.",
    buttonText: "Begin",
  },
  2: {
    heading:
      "Anonymous Health helps you manage habits, and puts you on a path to the life you want.",
    buttonText: "Continue",
  },
  3: {
    heading: "Whatever your habits may be - alcohol, gambling, social media…",
  },
  4: {
    heading: "Most of us need help on the path to change.",
    buttonText: "Open the Door",
  },
  5: { heading: "" },
  6: {
    heading: "Let's start with a few questions.",
    headingClass: "huge",
    buttonText: "Get Started",
  },
};

// Styled Components
const VideoContent = styled.div`
  align-items: center;
  color: ${colors.lightPoint};
  display: flex;
  flex-direction: column;
  margin: auto 0 2vw;
  max-width: 78em;
  min-height: 70vh;
  opacity: ${(props) => (props.$textVisible ? "1" : "0")};
  padding: 0 8vw;
  text-align: center;
  transition: opacity 0.5s ease-in-out;
  width: 100%;
  z-index: 2;

  > div {
    margin-top: auto;
  }
`;

// const RefreshLink = styled.a`
//   display: flex;
//   align-items: center;
//   gap: 0.4em;
//   color: ${colors.lightPoint};
//   padding: 1em 0;
//   margin-top: 1em;
//   opacity: 0.66;
//   mix-blend-mode: plus-lighter;

//   &:hover {
//     opacity: 1;
//   }

//   span {
//     font-weight: 800;
//     letter-spacing: -0.03em;
//   }

//   svg {
//     height: 1em;
//     width: 1em;

//     &, use {
//       fill: currentColor;
//     }
//   }
// `

const Step1 = ({
  playing = false,
  step = 1,
  stepForward,
  stepHeading = null,
}) => {
  const [textVisible, setTextVisible] = useState(false);
  const stepContentData = stepContent[step];
  const { heading, buttonText, headingClass } = stepContentData || {};

  // const handleRefresh = () => {
  //   window.location.reload();
  // };

  // Wrap the definition of the progress function with useCallback
  const progress = useCallback(() => {
    setTextVisible(false);

    setTimeout(() => {
      stepForward();
    }, 500);
  }, [stepForward]);

  useEffect(() => {
    setTimeout(() => {
      setTextVisible(true);
    }, 500);

    if (step === 3) {
      setTimeout(() => {
        progress();
      }, 4000);
    }

    if (step === 5) {
      setTimeout(() => {
        progress();
      }, 5000);
    }
    // eslint-disable-next-line
  }, [step]);

  return (
    <VideoContent $textVisible={textVisible}>
      <H1 className={headingClass}>
        <span dangerouslySetInnerHTML={{ __html: stepHeading || heading }} />
      </H1>

      {buttonText && <Button onClick={() => progress()}>{buttonText}</Button>}
    </VideoContent>
  );
};

export default Step1;
