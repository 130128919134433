import React, { PropsWithChildren } from "react";
import styled, { css } from "styled-components";
// Utilities
import * as mediaQuery from "../utilities/mediaQuery";

const H1Container = styled.div`
  font-size: 2.8em;
  font-weight: 900;
  letter-spacing: -0.03em;
  line-height: 1.2;
  margin-bottom: 0.5rem;

  ${mediaQuery.queryBelow(
    "bigPhone",
    css`
      font-size: 1.875em;
    `,
  )}

  &.huge {
    font-size: 3em;
    line-height: 1.1;

    ${mediaQuery.queryBelow(
      "bigPhone",
      css`
        font-size: 2.4em;
        margin-bottom: 1.2rem;
      `,
    )}
  }
`;

type H1Props = PropsWithChildren<{
  className?: string;
}>;

const H1: React.FC<H1Props> = ({ children, className }) => {
  return <H1Container className={`h1 ${className}`}>{children}</H1Container>;
};

export default React.memo(H1);
