import React, { PropsWithChildren } from "react";
import { Spinner } from "@chakra-ui/react";
import styled from "styled-components";
import * as colors from "../utilities/colors";

const ButtonContainer = styled.button`
  background-color: ${colors.brand};
  border-radius: 100em;
  color: ${colors.darkPoint};
  cursor: pointer;
  display: inline-block;
  font-size: 1.125em;
  font-weight: 900;
  letter-spacing: -0.03em;
  line-height: 1.4;
  padding: 0.875em 1.6em;
  text-align: center;
  min-width: 7em;

  &.full-width {
    width: 100%;
  }
  &.disabled {
    opacity: 0.2;
  }
`;
type ButtonProps = PropsWithChildren<{
  className?: string;
  disabled?: boolean;
  loading?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  style?: React.CSSProperties;
}>;

const Button: React.FC<ButtonProps> = ({
  children,
  className,
  disabled = false,
  loading = false,
  onClick,
  style,
}) => {
  return (
    <ButtonContainer
      className={[className, disabled ? "disabled" : ""].join(" ")}
      disabled={disabled}
      onClick={onClick}
      style={{ ...style, opacity: disabled ? 0.5 : 1 }}
    >
      {loading ? (
        <div
          style={{
            height: "32px",
            display: "flex",
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
            animation: "spin 1s linear infinite",

          }}
        >
          <svg
            width="100%"
            height="100%"
            viewBox="0 0 58 58"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.832 34.2533C13.8009 37.235 15.5754 39.8908 17.9594 41.9269C20.3434 43.963 23.244 45.3003 26.3406 45.7907C29.4372 46.2812 32.6091 45.9057 35.5056 44.706C38.4021 43.5062 40.9105 41.5288 42.7533 38.9923C44.5961 36.4559 45.7016 33.4593 45.9476 30.3338C46.1936 27.2083 45.5705 24.0756 44.1471 21.2822C42.7238 18.4887 40.5557 16.1432 37.8825 14.5051C35.2093 12.867 32.1352 12 29 12"
              stroke="#001712"
              strokeWidth="8"
              strokeLinecap="round"
            />
          </svg>
        </div>
      ) : (
        children
      )}
    </ButtonContainer>
  );
};

export default Button;
