import React, { useEffect, useRef, useState } from "react";
import { useRouter } from "next/router";
import styled, { css } from "styled-components";
// Utilities
import * as colors from "../utilities/colors";
import * as mediaQuery from "../utilities/mediaQuery";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Form from "../components/Steps/Form";
import Step from "../components/Steps/Step";
// Components
import SVGSprite from "../components/SVGSprite";

const AppContainer = styled.div`
  background-color: ${colors.black};
  overflow: hidden;

  ${mediaQuery.queryBelow(
    "bigPhone",
    css`
      border-radius: 24px 24px 0 0;
    `,
  )}
`;

const VideoFlowContainer = styled.div`
  align-items: center;
  background-color: ${colors.black};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  overflow: hidden;
  position: relative;
  width: 100%;
`;

const Video = styled.video`
  height: auto;
  left: 50%;
  min-height: 100%;
  min-width: 100%;
  max-width: none;
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: auto;
  z-index: 0;
  -webkit-mask-image: linear-gradient(black 30%, transparent);
  mask-image: linear-gradient(black 30%, transparent);

  ${mediaQuery.queryBelow(
    "bigPhone",
    css`
      height: 86vh;
      min-height: 86vh;
      top: 0;
      transform: translate(-50%, 0%);
    `,
  )}
`;

const stepVideoSeconds: Record<number, number> = {
  1: 0,
  2: 2.6,
  3: 6.6,
  4: 7.6,
  5: 12.6,
  6: 13,
};

function App() {
  const router = useRouter();
  const [renderForm, setRenderForm] = useState(false);
  const [playing, setPlaying] = useState(false);
  const [step, setStep] = useState(1);
  const [videoTime, setVideoTime] = useState<number>(0);
  const videoRef = useRef<any>(null);

  useEffect(() => {
    // Ensure that the video is set to play inline on mobile Safari
    videoRef.current?.setAttribute("playsinline", "");
    videoRef.current?.setAttribute("webkit-playsinline", "");
  }, []);

  const toggleVideo = React.useCallback((play: boolean) => {
    if (play) {
      videoRef.current?.play();
    } else {
      videoRef.current?.pause();
    }
  }, []);

  const onUpdateVideo = React.useCallback(
    (nextStep: number) => {
      if (nextStep < 6) {
        setPlaying(true);
        toggleVideo(true);
      }
    },
    [toggleVideo],
  );

  useEffect(() => {
    const endTime = stepVideoSeconds[step];
    if (playing && videoTime >= endTime) {
      setPlaying(false);
      toggleVideo(false);
    }
  }, [videoTime, step, toggleVideo, playing]);

  const onStepForward = React.useCallback(() => {
    setStep((prevStep) => {
      const nextStep = prevStep + 1;
      if (nextStep > 6) {
        router.push(`/step/${nextStep}`);
        setRenderForm(true);
        return prevStep;
      } else {
        onUpdateVideo(nextStep);
        return nextStep;
      }
    });
  }, [onUpdateVideo, router]);

  const onTimeUpdate = React.useCallback(() => {
    setVideoTime(videoRef.current.currentTime);
  }, []);

  return (
    <AppContainer className="App">
      <SVGSprite />
      <VideoFlowContainer>
        <Header className="" />
        <Step playing={playing} step={step} stepForward={onStepForward} />
        <Video
          playsInline
          muted
          ref={videoRef}
          poster="/assets/images/ah-signup-web-video-poster.jpg"
          preload="auto"
          onTimeUpdate={onTimeUpdate}
        >
          <source src="/assets/ah-signup-web.mp4" />
        </Video>
        <Footer />
      </VideoFlowContainer>
    </AppContainer>
  );
}

export default App;
